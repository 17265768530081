import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const OurStory = () => (
  <Layout>
    <div className="our-story-relative">
      <div className="main-image">
      <StaticImage 
        src="../assets/images/Hamby-1.jpg"
        width={2000}
        height={1000}
      />
      </div>
      <h1 className="our-story-welcome">Our Story</h1>
      <div className="our-story-intro">
        <h1>Welcome</h1>
        <p className="our-story-par">
        We are the Hamby Family, of Hamby Artisan Bakery. We opened our bakery in the fall of 2022, after our twin boys, Leo and Saul, had a dream about opening a cookie business. 
        As a family, we quickly went to work planning out flavors, testing recipes (lots of them!), and sharing our test cookies with friends and family. 
        Once we decided on our first set of flavors, we knew we were ready to share them with our community.
        <br />
        <br />
        As a family, we love spending time together. We are passionate about homeschooling, spending time outdoors, and cooking (and eating!) homemade, organic meals. 
        <br />
        <br />
        Our bakery is owned and operated by Chase and Candice Hamby. The recipes you enjoy here have been developed and heavily tested by Candice, Leo, and Saul. 
        Our daughter Caroline likes to help stir bowls of flour and taste cookies once they are done!
        We are thrilled that you're here, and hope you thoroughly enjoy our treats!
        </p>
      </div>
    </div>
    <div className="our-story-twins">
        <StaticImage 
          src="../assets/images/twins-color.jpg"
          width={2000}
          height={1000}
        />
      </div>
  </Layout>
)

export const Head = () => <Seo title="Our Story" />

export default OurStory
